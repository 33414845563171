$enable-ltr: true;
$enable-rtl: true;

@import "@coreui/coreui/scss/coreui";

// Global Styles
// -------------
// General styles applied to HTML elements

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style: none;
}

tr:nth-child(even):not(.noHighlight) {
  background-color: #f2f2f2;
}

tr:hover:not(.sTableHead) > td:not(.CDatePicker) > td {
  background-color: lightgrey;
}

// App Styles
// ----------
// Styles specific to the app layout and structure

.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

// Router Styles
// -------------
// Styles for the router component and its container

.RouterBorder {
  padding: 1em;
}

// Table Styles
// ------------
// Styles for tables, columns, and rows

.HeaderCol {
  // multiple lines should not be displayed, text should dissapear to conserve space
  white-space: nowrap;
  overflow: hidden;

  //flex: 1;
  text-align: center;
  width: 30em;
}
.table-sm > :not(caption) > * > * {
  padding: 0rem !important;
}

/*This is used to make data in the smart table one Line*/
.oneLine {
  overflow: hidden;
  white-space: nowrap;
}

/*This is used to make data in the smart table multi Line*/
.multiLine {
  overflow: hidden;
  white-space: normal;
}

// Discount Calendar Header Styles
// -------------------------------
// Styles for the discount calendar header and its elements

.discount-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.5em;
}

.col-left {
  float: left;
}

.col-right {
  float: right;
}

.discount-entry-card {
  width: 30em;
  margin: auto;
}

.discount-create-btn {
  float: right;
}

// should be next to the discount
.discount-close-btn {
  float: right;
}

.discount-update-btn {
  float: right;

  margin-right: 1em;
}

// Date Picker Styles
// ------------------
// Styles for the date picker and its container

// alignment to the right and margin should match the left side
.cDateCol {
  text-align: right;
  margin-right: 1em;

  padding: 0.5em;

  .CDatePicker {
    margin-right: 1em;
  }
}

// width of the date picker should match the width of the input field
.react-datepicker-wrapper {
  width: 75%;
}

// Navigation Styles
// -----------------
// Styles for the navigation elements and breadcrumbs

.nav-link {
  color: #fff;
}

.NavButton {
  // on hover the button should be highlighted
  &:hover {
    background-color: wheat;
  }
}

.breadcrumb {
  width: 15em; // Set a fixed width for the breadcrumb container
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.align-items-start {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// Button Text Styles
// ------------------
// Styles for text buttons that look like regular text but are clickable

/*This hides typical button components to make a simple button on text that is clickable */
.buttonText {
  cursor: pointer;
  border: none;
  background: none;
}

// Dropdown Styles
// ------------------
// Styles for dropdowns and their elements
//when class .dark-theme is not present
body:not(.dark-theme) .highlightOption {
  background-color: transparent !important;
  color: black !important;
  // on hover
  &:hover {
    background-color: #0096fb !important;
    color: white !important;
  }
}

// Discount Calendar Header Styles

.header-container-discountcalendar {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.month-arrow {
  z-index: 2;
}

.month-year {
  margin: 0 5px;
  font-size: 1.2em;
  font-weight: bold;
}

.year-dropdown {
  z-index: 2;
}

.year-dropdown-menu {
  z-index: 2;
}

// App Header Styles
// -----------------
// Styles for the app header and its elements

.AppHeader {
  .HeaderCol {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: transparent transparent; /* For Firefox */

    &::-webkit-scrollbar {
      width: 6px; /* For Chrome, Safari, and Edge */
      background-color: transparent; /* For Chrome, Safari, and Edge */
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent; /* For Chrome, Safari, and Edge */
    }

    &:hover {
      scrollbar-color: rgba(0, 0, 0, 0.2) rgba(255, 255, 255, 0.1); /* For Firefox */

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2); /* For Chrome, Safari, and Edge */
      }
    }
    display: flex;
    justify-content: center;
    gap: 0.1rem;
    flex-wrap: nowrap;
    overflow-x: auto;
    max-width: calc(100% - 20em);
  }

  .NavButton {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    position: relative;
    padding: 0.25rem;
  }

  .nav-link {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    padding: 0;
  }

  .selected {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 2px;
      background-image: linear-gradient(
        to right,
        rgba(255, 165, 0, 1) 0%,
        rgba(255, 140, 0, 1) 50%,
        rgba(255, 215, 0, 1) 100%
      );
    }
  }

  // Media queries for dynamic spacing
  @media (min-width: 768px) {
    .NavButton {
      padding: 0.5rem;
    }
  }

  @media (min-width: 992px) {
    .NavButton {
      padding: 0.75rem;
    }
  }

  @media (min-width: 1200px) {
    .NavButton {
      padding: 0.85rem;
    }
  }
}

// Table Row Styles
// ----------------
// Styles for table rows and their elements
tr {
  font-size: 10px; // Adjust the value to your preference
  padding-top: 0; // Remove top padding
  padding-bottom: 0; // Remove bottom padding
}

// Dark Theme Styles
// -----------------
// Styles for the dark theme, including variables for colors

$dark-bg-color: #121212;
$dark-text-color: #f5f5f5;
$dark-link-color: #bb86fc;
$dark-highlight-color: #3d3d3d;
$dark-hover-bg-color: #1f1f1f;
$dark-card-border-color: #3d3d3d;

.dark-theme {
  background-color: $dark-bg-color;
  color: $dark-text-color;

  .option {
    background-color: $dark-bg-color;
    color: $dark-text-color;
  }

  input {
    background-color: $dark-bg-color;
    color: $dark-text-color;
    // apply for hover and focus
    &:hover,
    &:focus {
      background-color: $dark-hover-bg-color;
      // make sure text color is visible
      color: $dark-text-color;
    }
  }

  .header {
    background-color: $dark-hover-bg-color;
  }

  // Add dark theme styles for other elements
  .App-header {
    background-color: $dark-hover-bg-color;
  }

  .App-link {
    color: $dark-link-color;
  }

  // Add styles for table rows (td's) text color
  table {
    thead {
      th {
        color: $dark-text-color;
      }
    }
    .d-inline {
      color: $dark-text-color;
    }

    tbody {
      tr {
        td {
          color: $dark-text-color;
        }

        &:nth-child(even):not(.noHighlight) {
          background-color: #2c2c2c;
          color: $dark-text-color;
        }

        &:hover:not(.sTableHead) > td:not(.CDatePicker) {
          background-color: $dark-highlight-color;
          color: $dark-text-color;
        }
      }
    }
  }

  // Add styles for other text elements
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  li {
    color: $dark-text-color;
    background-color: transparent;
  }
  .dropdown-item {
    color: $dark-text-color;
    background-color: $dark-bg-color;
    .form-check-input {
      background-color: blue;
    }
  }
  .dropdown-menu {
    background-color: $dark-bg-color;
    border-color: $dark-card-border-color;
  }

  // Add dark theme styles for CoreUI components
  .nav-link {
    color: $dark-text-color;
  }

  .breadcrumb {
    color: $dark-text-color;
  }

  .btn {
    background-color: $dark-hover-bg-color;
    color: $dark-text-color;

    &:hover,
    &:focus,
    &:active {
      background-color: $dark-highlight-color;
      color: $dark-text-color;
    }
  }
  button {
    background-color: transparent;

    color: $dark-text-color;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: $dark-text-color;
    }
  }
  .form-control:disabled {
    background-color: $dark-bg-color;
    color: $dark-text-color;
    // distinguish disabled input from enabled input
    opacity: 0.5;
  }
  .card {
    background-color: $dark-bg-color;
    border-color: $dark-card-border-color;
    color: $dark-text-color;
  }

  .card-header {
    background-color: $dark-hover-bg-color;
    border-color: $dark-card-border-color;
  }

  .sidebar {
    background-color: $dark-hover-bg-color;
  }

  .sidebar-nav .nav-link .page-link {
    color: $dark-text-color;
    background-color: $dark-bg-color;
  }
  .pagination .page-item {
    background-color: $dark-bg-color;
    color: $dark-text-color;
  }
  .modal-body {
    background-color: $dark-bg-color;
    color: $dark-text-color;
  }
  .modal-header {
    background-color: $dark-hover-bg-color;
    color: $dark-text-color;
  }

  thead {
    color: $dark-text-color;
  }
  .popover-header {
    background-color: $dark-hover-bg-color;
    color: $dark-text-color;
  }

  .popover {
    background-color: $dark-bg-color;
    color: $dark-text-color;
  }
  DatePicker {
    background-color: $dark-bg-color;
    color: $dark-text-color;
  }
  .month-year {
    color: black;
  }
}

@media (max-width: 768px) {
  .NavButton {
    width: 100%;
    margin-bottom: 5px;
    margin-right: 0;
  }
}

.sTable {
  z-index: -1;
}

span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.hidden {
  display: none;
}

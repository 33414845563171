.CCardBorder {
  border: none;
  border-radius: 5px;
  padding: 20px;
  width: 30em;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.loginForm {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10em;
}

.loginFormLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.InputFormUsername,
.InputFormPassword,
.optCode,
.NewPassword,
.NewConfirmPassword {
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  font-size: 1em;
  &:focus {
    border: 1px solid #0094c3;
    outline: none;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
}

.loginButton {
  cursor: pointer;
  padding: 10px;
  background-color: #0094c3;
  border: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: #007aab;
    color: #ffffff;
  }
}

.forgotPassword {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #0094c4;
  }
}
.buttonGroup {
  display: flex;
  justify-content: space-between;
}

.backToLogin {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #0094c4;
  }
}

:root {
  --focus-border: #007fd4;
  --separator-border: #838383;
}

.divAllotment {
  width: 98vw;
  height: 90vh;
}

.divScrollable {
  overflow: auto;
  width: 100%;
  height: 100%;
}
